* {
  text-decoration: none;
  font-family: var(--font);
}

.BlogPage {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--darkBackground);
  
  .navbar {
    display: flex;
    top: 0;
    position: fixed;
    height: 120px;
    align-items: center;
    width: 100%;
    z-index: 100000;
    background: rgba(255, 255, 255, 0);
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: none;
  }

  .navbar.scrolled {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .navbar .resume {
    position: fixed;
    top: 10px;
    left: 10px;
  }

  .navbar .resumeButton {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 1.5rem;
    padding-inline: 3.25rem;
    background-color: var(--darkBackground);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 25px;
    cursor: pointer;
  }

  .navbar .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }

  .navbar .resumeButton:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }

  .navbar .resumeButton:hover .icon {
    transform: translate(4px);
  }

  .navbar .resumeButton:hover::before {
    animation: shine 1.5s ease-out infinite;
  }

  .navbar .resumeButton::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }
    60% {
      left: 100%;
    }
    to {
      left: 100%;
    }
  }

  .navbar .menu {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 1000;
  }
  .BlogComponent {
    width: 100%;
    align-items: center;
    .home_button {
      padding: 8px 12px;
      color: var(--borderColor);
      font-size: 1vw;
      margin-left: 10px;
      cursor: pointer;
      align-self: flex-start;
      margin: 10px;
      width: 100px;
      border: 2px solid var(--borderColor);
      background-color: transparent;
      transition: background-color 0.3s ease, color 0.3s ease;
      border-radius: 4px;
      text-decoration: none;
    }

    .home_button:hover {
      background-color: var(--borderColor);
      color: #fff;
    }

    .blog_container {
      font-family: var(--font);
      .blog_card {
        margin: 10px;
        padding: 10px;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(10px);
        border-radius: 8px;

        .published_date {
          color: var(--borderColor);
          margin-top: auto;
          text-align: right;
        }

        .blog_title {
          font-size: 1.5vw;
          margin: 0;
          padding: 0;
          color: var(--borderColor);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .blog_desc {
          color: var(--darkFontColor);
          font-size: 1vw;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          margin-top: 10px;
        }

        .read_button {
          padding: 8px 12px;
          color: var(--borderColor);
          font-size: 1vw;
          cursor: pointer;
          align-self: flex-end;
          margin-top: 10px;
          border: 2px solid var(--borderColor);
          background-color: transparent;
          transition: background-color 0.3s ease, color 0.3s ease;
          border-radius: 4px;
          text-decoration: none;
        }

        .read_button:hover {
          background-color: var(--borderColor);
          color: #fff;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .home_button {
        font-size: 5vw;
        // margin: 0;
        margin-bottom: 5px;
      }

      .blog_container {
        padding: 0;
        justify-content: center;

        .blog_card {
          margin: 10px;

          .blog_title {
            font-size: 4vw;
          }

          .blog_desc {
            font-size: 3vw;
          }

          .read_button {
            font-size: 2vw;
          }
        }
      }
    }
  }
}

.HomeIntroComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  .blog-button {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 1.5rem;
    padding-inline: 3.25rem;
    background-color: var(--darkBackground);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 25px;
    cursor: pointer;
  }

  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }

  .blog-button:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }

  .blog-button:hover .icon {
    transform: translate(4px);
  }

  .blog-button:hover::before {
    animation: shine 1.5s ease-out infinite;
  }

  .blog-button::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }

    60% {
      left: 100%;
    }

    to {
      left: 100%;
    }
  }

  p {
    font-size: 5vw;
    font-family: var(--font);
    font-weight: 200;
    margin: 10px;
    color: var(--darkFontColor);
    span {
      color: var(--borderColor);
    }
  }
  .imageDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 30%;
      object-fit: contain;
      border-radius: 50%;
      margin-bottom: 10px;
    }
    span {
      font-family: var(--font);
      font-size: 2em;
      font-size: 3vw;
      color: var(--darkFontColor);
    }
  }
}

.MenuComponent {
  display: flex;
  flex-direction: column;

  .menuButton {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 1.5rem;
    padding-inline: 3.25rem;
    background-color: var(--darkBackground);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 25px;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
      border-color: #fff9;
    }

    .icon {
      width: 24px;
      height: 24px;
      transition: all 0.3s ease-in-out;
    }

    &:hover .icon {
      transform: translate(4px);
    }

    &::before {
      content: "";
      position: absolute;
      width: 100px;
      height: 100%;
      background-image: linear-gradient(
        120deg,
        rgba(255, 255, 255, 0) 30%,
        rgba(255, 255, 255, 0.8),
        rgba(255, 255, 255, 0) 70%
      );
      top: 0;
      left: -100px;
      opacity: 0.6;
    }

    &:hover::before {
      animation: shine 1.5s ease-out infinite;
    }
  }

  @keyframes shine {
    0% {
      left: -100px;
    }

    60% {
      left: 100%;
    }

    to {
      left: 100%;
    }
  }

  .menuButtons {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    button {
      position: relative;
      transition: all 0.3s ease-in-out;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
      padding-block: 1.5rem;
      padding-inline: 3.25rem;
      background-color: var(--darkBackground);
      border-radius: 9999px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffff;
      gap: 10px;
      font-weight: bold;
      border: 3px solid #ffffff4d;
      outline: none;
      width: auto;
      overflow: hidden;
      font-size: 25px;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        transform: scale(1.05);
        border-color: #fff9;
      }

      .icon {
        width: 24px;
        height: 24px;
        transition: all 0.3s ease-in-out;
      }

      &:hover .icon {
        transform: translate(4px);
      }

      &::before {
        content: "";
        position: absolute;
        width: 100px;
        height: 100%;
        background-image: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0) 70%
        );
        top: 0;
        left: -100px;
        opacity: 0.6;
      }

      &:hover::before {
        animation: shine 1.5s ease-out infinite;
      }
    }

    @keyframes shine {
      0% {
        left: -100px;
      }

      60% {
        left: 100%;
      }

      to {
        left: 100%;
      }
    }
  }
}

.AboutComponent {
  display: flex;
  height: 100%;
  width: 100%;
  .dp_img {
    display: flex;
    width: 50%;
    height: inherit;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
      object-fit: contain;
    }
  }
  .info {
    display: flex;
    width: 52%;
    .aboutContent {
      padding-left: 10px;
    }
    justify-content: center;
    flex-direction: column;
    .aboutContent {
      font-size: 1.2vw;
      color: var(--darkFontColor);
      line-height: 1.8;
    }
    .highlights {
      color: var(--borderColor);
    }
    .title {
      font-size: 7vw;
      padding: 0;
      margin: 0;
      font-family: var(--font);
      color: var(--borderColor);
      text-align: left;
    }
    .SocialIcons {
      display: flex;
      height: 50px;
      padding-left: 10px;
      cursor: pointer;
      padding-bottom: 10px;
      .icon {
        font-size: 50px;
        padding-right: 20px;
        cursor: pointer;
        color: var(--borderColor);
      }
      .leetCodeicon {
        width: 45px;
        object-fit: contain;
      }
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400&display=swap");

.design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--darkBackground);
  // min-height: 100vh;
  padding: 50px 0;
  font-family: var(--font);
}

.timeline {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
  }
}

.timeline-content {
  padding: 1.5rem;
  background: #1f1f1f;
  border-radius: 6px;
  color: white;
  transition: 0.4s ease;
  overflow-wrap: break-word !important;
  margin: 1rem 0;

  h3 {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: var(--borderColor);
  }

  p {
    font-size: clamp(0.8rem, 1.2vw, 1.2rem);
    margin-bottom: 0.25rem;
    color: var(--darkFontColor);
  }

  .instiName {
    font-style: italic;
    color: #bbb;
    margin-top: 2px;
  }
}

.timeline-component {
  margin: 0 0 20px 0;

  @media screen and (min-width: 768px) {
    margin: 0 20px 20px 20px;
  }
}

.timeline-middle {
  position: relative;
  background-image: linear-gradient(45deg, #f27121, #e94057, #8a2387);
  width: 3px;
  height: 100%;
}

.timeline-circle {
  position: absolute;
  top: 0;
  left: 50%;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-image: linear-gradient(45deg, #f27121, #e94057, #8a2387);
  transform: translateX(-50%);
}

.timeline-empty {
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }
}

.title {
  font-size: clamp(2rem, 7vw, 4rem);
  padding: 0;
  margin: 0 0 2rem 0;
  font-family: var(--font);
  color: var(--darkFontColor);
  text-align: center;
}

.SkillComponent {
  align-items: center;

  // .title {
  //   font-size: 7vw;
  //   padding: 0;
  //   padding-bottom: 10px;
  //   margin: 0;
  //   margin-left: 10px;
  //   font-family: var(--font);
  //   color: var(--borderColor);
  //   text-align: left;
  // }

  button {
    color: var(--darkFontColor);
    font-family: var(--font);
    font-size: 1.5vw;
    text-transform: none;
  }

  .skills_container {
    font-family: var(--font);

    .skill_card {
      padding: 10px;
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px);
      border-radius: 8px;
      color: var(--borderColor);
      font-size: 1.2vw;
    }
  }
}

.ProjectComponent {
  width: 100%;
  align-items: center;
  .title {
    font-size: 7vw;
    padding: 0;
    padding-bottom: 10px;
    margin: 0;
    font-family: var(--font);
    color: var(--borderColor);
    text-align: left;
  }
  button {
    color: var(--darkFontColor);
    font-family: var(--font);
    font-size: 1.5vw;
    text-transform: none;
  }
  .project_container {
    font-family: var(--font);
    .project_card {
      padding: 10px;
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(10px);
      border-radius: 8px;
      .project_title {
        font-size: 2vw;
        margin: 0;
        padding: 0;
        color: var(--borderColor);
      }
      .project_desc {
        color: var(--darkFontColor);
        font-size: 1.2vw;
      }
      .project_icon,
      .github_icon {
        padding-right: 5px;
        color: var(--borderColor);
        font-size: 2vw;
        cursor: pointer;
      }
    }
  }
}

.ExperienceComponent,
.EducationComponent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactComponent {
  .title {
    font-size: 7vw;
    text-align: center;
    color: var(--borderColor);
  }
  .mail_Card {
    background: var(--darkBackground);
    height: 10vw;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .contact {
      display: flex;
      padding-top: 10px;
      align-items: center;
      .emailIcon {
        font-size: 3vw;
        padding-right: 10px;
        color: var(--borderColor);
      }
      a {
        color: aliceblue;
        text-decoration: none;
        color: var(--borderColor);
        font-size: 3vw;
      }
    }

    .copyright {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      font-size: 2vw;
    }
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .AboutComponent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .dp_img {
      position: relative;
      display: flex;
      order: 1;
      width: 100%;
      height: min-content;
      justify-content: center;
      align-items: center;
      img {
        width: 50%;
        object-fit: contain;
      }
    }
    .info {
      position: relative;
      width: 100%;
      order: 2;
      .aboutContent {
        font-size: 3vw;
        line-height: 1.3;
      }
      .title {
        font-size: 10vw;
        color: var(--borderColor);
      }
      .SocialIcons {
        height: 30px;
        .icon {
          font-size: 30px;
        }
        .leetCodeicon {
          width: 25px;
          object-fit: contain;
        }
      }
    }
  }
  .ProjectComponent {
    button {
      font-size: 4vw;
    }
    .project_container {
      .project_card {
        .project_title {
          font-size: 2vw;
        }
        .project_desc {
          font-size: 1.2vw;
        }
        .project_icon,
        .github_icon {
          font-size: 2vw;
          cursor: pointer;
        }
      }
    }
  }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .AboutComponent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .dp_img {
      position: relative;
      display: flex;
      order: 1;
      width: 100%;
      height: min-content;
      img {
        width: 80%;
        object-fit: contain;
      }
    }
    .ProjectComponent {
      button {
        font-size: 4vw;
      }
      .project_container {
        .project_card {
          .project_title {
            font-size: 5vw;
          }
          .project_desc {
            font-size: 6 vw;
          }
          .project_icon,
          .github_icon {
            font-size: 8vw;
            cursor: pointer;
          }
        }
      }
    }
    .info {
      position: relative;
      width: 100%;
      order: 2;
      .aboutContent {
        font-size: 4vw;
        line-height: 1.3;
      }
      .title {
        font-size: 10vw;
        color: var(--borderColor);
      }
      .SocialIcons {
        height: 30px;
        .icon {
          font-size: 30px;
        }
        .leetCodeicon {
          width: 25px;
          object-fit: contain;
        }
      }
    }
  }
  .BlogPage {
    margin-top: 100px;
  }
  .ProjectComponent {
    button {
      font-size: 4vw;
    }
    .project_container {
      .project_card {
        .project_title {
          font-size: 4vw;
        }
        .project_desc {
          font-size: 2vw;
        }
        .project_icon,
        .github_icon {
          font-size: 5vw;
          cursor: pointer;
        }
      }
    }
  }
  .SkillComponent {
    .title {
      font-size: 10vw;
    }
    button {
      font-size: 4vw;
    }
    .skills_container {
      .skill_card {
        font-size: 4vw;
      }
    }
  }
}
@media only screen and (min-width: 250px) and (max-width: 480px) {
  .HomeIntroComponent {
    p {
      font-size: 10vw;
    }
    .imageDiv {
      img {
        width: 70%;
      }
      span {
        font-size: 5vw;
      }
    }
  }
  .BlogPage {
    margin-top: 70px;
    .navbar {
      height: 70px;
      .resume {
        .resumeButton {
          padding-block: 0.5rem;
          padding-inline: 1.25rem;
          font-size: 15px;
        }
      }
    }
  }
  .AboutComponent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .dp_img {
      position: relative;
      display: flex;
      order: 1;
      width: 100%;
      img {
        width: 80%;
        object-fit: contain;
      }
    }
    .info {
      position: relative;
      width: 100%;
      order: 2;
      .aboutContent {
        font-size: 4vw;
        line-height: 1.3;
      }
      .title {
        font-size: 10vw;
        color: var(--borderColor);
      }
      .SocialIcons {
        height: 30px;
        .icon {
          font-size: 30px;
        }
        .leetCodeicon {
          width: 25px;
          object-fit: contain;
        }
      }
    }
  }
  .EducationComponent {
    p,
    .matriculation,
    .intermediate,
    .btech {
      .timeline {
        font-size: 4vw;
      }
      .educationDescription {
        font-size: 3vw;
      }
    }
  }
  .ProjectComponent {
    button {
      font-size: 4vw;
    }
    .project_container {
      .project_card {
        .project_title {
          font-size: 5vw;
        }
        .project_desc {
          font-size: 4vw;
        }
        .project_icon,
        .github_icon {
          font-size: 8vw;
          cursor: pointer;
        }
      }
    }
  }
  .ExperienceComponent {
    .title {
      font-size: 10vw;
    }
    .experiences {
      .volunter_experience {
        .title {
          font-size: 10vw;
        }
      }
      .professional_experience,
      .volunter_experience {
        .experience_card {
          .position_name {
            font-size: 5vw;
          }
          .orgDetails {
            display: flex;
            justify-content: space-between;
            .org,
            .duration {
              color: var(--borderColor);
              font-size: 4vw;
            }
          }

          .desc {
            color: var(--darkFontColor);
            font-size: 3vw;
            line-height: 1.3;
          }
        }
      }
    }
  }
  .MenuComponent {
    #menuButton {
      padding-block: 0.5rem;
      padding-inline: 1.25rem;
      font-size: 15px;
    }
    .menuButtons {
      button {
        padding-block: 0.5rem;
        padding-inline: 1.25rem;
        font-size: 15px;
      }
    }
  }
  .HomeIntroComponent {
    .blog-button {
      padding-block: 0.5rem;
      padding-inline: 1.25rem;
      font-size: 15px;
    }
  }
  .SkillComponent {
    .title {
      font-size: 10vw;
    }
    button {
      font-size: 4vw;
    }
    .skills_container {
      .skill_card {
        font-size: 4vw;
      }
    }
  }
  .ContactComponent {
    .title {
      font-size: 20vw;
      text-align: start;
      margin-bottom: 10px;
      color: var(--borderColor);
    }
    .mail_Card {
      padding-bottom: 20px;
      .contact {
        .emailIcon {
          font-size: 10vw;
        }
        a {
          color: var(--borderColor);
          font-size: 10vw;
        }
      }

      .copyright {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        font-size: 2vw;
      }
    }
  }
}
