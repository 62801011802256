.HomePage {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--darkBackground);
  align-items: center;
  justify-content: center;

  .navbar {
    display: flex;
    position: fixed;
    height: 120px;
    align-items: center;
    width: 100%;
    z-index: 100000;
    background: rgba(255, 255, 255, 0);
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    box-shadow: none;
  }

  .navbar.scrolled {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .navbar .resume {
    position: fixed;
    top: 10px;
    left: 10px;
  }

  .navbar .resumeButton {
    position: relative;
    transition: all 0.3s ease-in-out;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    padding-block: 1.5rem;
    padding-inline: 3.25rem;
    background-color: var(--darkBackground);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
    gap: 10px;
    font-weight: bold;
    border: 3px solid #ffffff4d;
    outline: none;
    overflow: hidden;
    font-size: 25px;
    cursor: pointer;
  }

  .navbar .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease-in-out;
  }

  .navbar .resumeButton:hover {
    transform: scale(1.05);
    border-color: #fff9;
  }

  .navbar .resumeButton:hover .icon {
    transform: translate(4px);
  }

  .navbar .resumeButton:hover::before {
    animation: shine 1.5s ease-out infinite;
  }

  .navbar .resumeButton::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
      120deg,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0) 70%
    );
    top: 0;
    left: -100px;
    opacity: 0.6;
  }

  @keyframes shine {
    0% {
      left: -100px;
    }
    60% {
      left: 100%;
    }
    to {
      left: 100%;
    }
  }

  .navbar .menu {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 1000;
  }

  .loader {
    position: absolute;
    top: 40vh;
  }

  #LandingSection {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  #ProjectSection {
    width: 100%;
  }

  #ExperienceSection {
    width: 100%;
  }

  #ContactSection {
    width: 100%;
  }
}

@media only screen and (min-width: 250px) and (max-width: 480px) {
  .HomePage {
    .navbar {
      height: 70px;
      .resume {
        .resumeButton {
          padding-block: 0.5rem;
          padding-inline: 1.25rem;
          font-size: 15px;
        }
      }
    }
  }
}
